import axios from "axios";

export const URL_DEFAULT ='https://monitoring.agatech.ru/query/axi.php';

// Функция запроса для выполнения запроса на вход
export async function LoginIn(data) {
    data.type = "loginIn";
    try {
        const response = await axios({
            method: 'POST',
            url: URL_DEFAULT,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        });
        
        return response.data.success;
    } catch (error) {
        console.error(error);
        throw error; // Передаем ошибку дальше
    }
}



// Функция запроса для выполнения запроса на вход
export async function SiteList() {
    const data = {type : "siteList"};
    try {
        const response = await axios({
            method: 'POST',
            url: URL_DEFAULT,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        });
        
        return response.data.success;
    } catch (error) {
        console.error(error);
        throw error; // Передаем ошибку дальше
    }
}


// Функция запроса для выполнения запроса на состояние сайта
export async function SiteInfo(domain) {
    const data = {domain:domain, type : "siteInfo"};
    try {
        const response = await axios({
            method: 'POST',
            url: URL_DEFAULT,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        });
        
        return response.data.success;
    } catch (error) {
        console.error(error);
        throw error; // Передаем ошибку дальше
    }
}

// Функция запроса по добавлению записи для мониторинга
export async function SiteAdd(domain) {
    const data = {domain:domain, type : "siteAdd"};
    try {
        const response = await axios({
            method: 'POST',
            url: URL_DEFAULT,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        });
        
        return response.data.success;
    } catch (error) {
        console.error(error);
        throw error; // Передаем ошибку дальше
    }
}

// Функция запроса по редактированию записи для мониторинга
export async function SiteUpdate(domain) {
    const data = {domain:domain, type : "siteUpdate"};
    try {
        const response = await axios({
            method: 'POST',
            url: URL_DEFAULT,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        });
        
        return response.data.success;
    } catch (error) {
        console.error(error);
        throw error; // Передаем ошибку дальше
    }
}

// Функция запроса по удалению записи для мониторинга
export async function SiteDelete(id) {
    const data = {id:id, type : "siteDelete"};
    try {
        const response = await axios({
            method: 'POST',
            url: URL_DEFAULT,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        });
        
        return response.data.success;
    } catch (error) {
        console.error(error);
        throw error; // Передаем ошибку дальше
    }
}


// Функция запроса по удалению записи для мониторинга
export async function exitUser(id) {
    const data = { type : "exitUser"};
    try {
        const response = await axios({
            method: 'POST',
            url: URL_DEFAULT,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        });
        
        return response.data.success;
    } catch (error) {
        console.error(error);
        throw error; // Передаем ошибку дальше
    }
}