import { Button, Container } from "react-bootstrap";
import { exitUser } from "../../axi";


export default function Header({setIsAuthenticated}) {

    function exitSistem() {
        exitUser()
        setIsAuthenticated(false)
    }
    
    return(
        <header className="header">
        <Container className="header_container flex">
            <img className="header_logo" src="/logo_lite.png" alt="" />
            <Button onClick={()=>exitSistem()} className="bek">
                Выйти
            </Button>
        </Container>
    </header>
    )
}